import React from 'react'
import realSanti from '../../assets/LeonPng.png'

const SantiSmile = () => {
  return (
      <>
        <img src={realSanti} className="santiImage" alt="in Budapest" />
      </>
  )
}

export default SantiSmile