import React from "react";
import chamomile from "../../assets/chamomile-48751.png";

const Chamomile = () => {
  return (
    <>
      <img className="chamomilePng" src={chamomile} alt={"chamomile png"} />
    </>
  );
};

export default Chamomile;
